import logo from './logo.svg';
import './App.css';

import React, { useState, useEffect } from 'react';
import { Table,Container,Row,Col, Badge, UncontrolledCollapse,
  Card,CardHeader,CardBody,CardText,Button,Form, FormGroup, Label, Input, ButtonToggle } from 'reactstrap';

import 'bootstrap/dist/css/bootstrap.min.css';

import {data} from "./data";

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCi6SUJ1lcZuZvWYN0my03gK6ALAwvsIAQ",
  authDomain: "arc-london.firebaseapp.com",
  databaseURL: "https://arc-london-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "arc-london",
  storageBucket: "arc-london.appspot.com",
  messagingSenderId: "106183403691",
  appId: "1:106183403691:web:a4051bda05ffd720452fee",
  measurementId: "G-EKYZ1FKPTL"
};

const app = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore(app);

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  ],
};

function SecretArea(props){
  const [isSignedIn, setIsSignedIn] = useState(false);
  
  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
      if (user === null) return;
      props.setUser(user);
      setIsSignedIn(1);
      
      db.collection("approved").where("email", "==", user.email).get().then((snapshot) => {
        if (snapshot.docs.length > 0)
          setIsSignedIn(2);
      })

    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  if (isSignedIn > 1)
    return props.children;
  else if(isSignedIn == 1){
    return (<div className="secretArea">
        <h3><img src="/lock.svg" width="80px"/>Approval from administrators pending</h3>
        <h3>You will be notified by E-mail once you are approved</h3>
      </div>);
  }

  return (<div className="secretArea">
    <h3><img src="/lock.svg" width="80px"/>Please login to access the food schedule.</h3>
    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
  </div>);
}

let lastId = 0;

function id(prefix='id') {
    lastId++;
    return `${prefix}${lastId}`;
}

let now = Date.now();
//let now = Date.parse("03/29/2022 12:00:01 GMT");

function get_state(time){
  if(time === null)
    return "upcoming";

  var hours = (time - now) / 36e5;

  if (hours < 0)
    return "dead";

  var hour = new Date(time).getHours();

  if((hour == 0 && hours < 7) || 
     (hour == 16 && hours < 4) ||
     (hour == 12 && hours < 12))
    return "next";

  return "upcoming";
}

function BookingMenu({event, type, user}){
  const [eventData,setEventData] = useState({"capacity":event["capacity"],"status":0,"signups":0});
  const [doc, setDoc] = useState({});
  const col = type+"_booking";

  const queryBooking = () => {
    db.collection(col).where("event", "==", event["name"]).where("email", "==", user.email).get().then((snapshot) => {
      var status = 0;
      if (snapshot.docs.length > 0){
        const doc = snapshot.docs[0].data(); 
        status =  doc["approved"]+1;
        setDoc(snapshot.docs[0]);
      }

      db.collection(col).where("event", "==", event["name"]).where("approved","==",true).get().then((snapshot) => {
        setEventData({...eventData,"status":status,"signups":snapshot.docs.length});
      });
    });

  };

  useEffect(() => {
   queryBooking();
  },[]);

  const register = () => {
    db.collection(col).add({
      "email": user.email,
      "event": event["name"],
      "approved": false
    });

   queryBooking();
  };


  const deregister = () => {
    doc.ref.delete();
    queryBooking();
  };

  if (eventData.signups >= eventData.capacity)
    return <Button color="danger" disabled={true}>Event full</Button>
  if(eventData.status == 0)
    return <Button color="info" onClick={register}>Sign up for event <br/>({eventData.signups}/{eventData.capacity})</Button>
  else if(eventData.status == 1)
      return <Button color="info" disabled={true}>Interest registered  <br/> (waiting for approval)</Button>
  else if(eventData.status == 2)
    return <Button color="success" onClick={deregister}>You're registered <br/> Cancel?</Button>


  return <Button color="warning" disabled={true}>Error</Button>
}

function Restaurant(entry,time=null,user=null){
  let uid = id();

  let state = get_state(time);

  return <Card bg="muted" 
            style={
                (state == "dead")?{
                  filter:"blur(1px) brightness(0.5)"
                }:(state=="next"?{
                  border:"4px solid orange",
                  boxShadow: "5px 5px #888888"
                  }:{})}>
    <CardHeader className="text-center">      
     <Badge className="bg-success"><img height="15px" src="/food.svg"/> {entry["type"]}</Badge>&nbsp;
     <Badge className="bg-dark"><img height="15px" src="/walk.svg"/> {entry["dist"]}m</Badge>&nbsp;
     <Badge className="bg-danger"><img height="15px" src="/time.svg"/> {entry["opening"]}</Badge> <br/>
     <br/>
     <a href={entry["url"]} target="_blank">{entry["name"]}</a>
    </CardHeader>
    <CardBody>
      <div id={uid}><i>Description &#x25BC;</i></div>
      <UncontrolledCollapse toggler={"#"+uid}>
        <CardText style={{}}>{entry["desc"]}
        </CardText>
        <br/>
      </UncontrolledCollapse>
      <CardText>
        Located at <a href={
        "https://www.google.com/maps/search/?api=1&query="+(entry["name"].split(" ").concat(entry["location"].split(" "))).join("+")
      } target="_blank">{entry["location"]}</a>
      </CardText>
    </CardBody>
    {user!==null && <BookingMenu event={entry} type="food" user={user}/>}
  </Card>;
  /*return (<tr>
    <td><a href={entry["url"]} target="_blank"> {entry["name"]}</a></td>
    <td><a href={
      "https://www.google.com/maps/search/?api=1&query="+(entry["name"].split(" ").concat(entry["location"].split(" "))).join("+")
    } target="_blank">{entry["location"]}</a></td>
  </tr>);*/
}
 
function App() {

  const [user, setUser] = useState({});
  const [schedule, setSchedule] = useState({});

  const setUserCB = u => this.setUser(u);

  useEffect(() => {
    if (user != {})
      db.collection("food_schedule").get().then((snapshot) => {
        var s = {}

        for(let i = 0; i < snapshot.docs.length; ++i){
          const d = snapshot.docs[i].data();
          s[d["schedule_id"]] = d;
        }

        setSchedule(s);
      });
  },[user]);

  return (<Container id={"container"} fluid className={"bg-dark text-light"}>
    <Row>
      <Col><h1 className={"text-center"}><img width={"60px"} src="https://aynrandcentre.co.uk/wp-content/uploads/2020/07/ARC-LOGO_LIGHT-GREEN-SVG.svg"/> Food Guide</h1></Col>
    </Row>
    <br/>
    <Row>
    <SecretArea setUser={setUser}>
      {Object.keys(schedule).length == 15 && <Table responsive borderless>
        <thead>
          <tr>
            <th className="fixed">Time</th>
            <th>28/03<br/>Monday</th>
            <th>29/03<br/>Tuesday</th>
            <th>30/03<br/>Wednesday</th>
            <th>31/03<br/>Thursday</th>
            <th>1/04<br/>Friday</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th className="fixed">Dinner</th>
            <td>{Restaurant(schedule[10],Date.parse("03/28/2022 23:00:00 GMT"),user)}</td>
            <td>{Restaurant(schedule[11],Date.parse("03/29/2022 23:00:00 GMT"),user)}</td>
            <td>{Restaurant(schedule[12],Date.parse("03/30/2022 23:00:00 GMT"),user)}</td>
            <td>{Restaurant(schedule[13],Date.parse("03/31/2022 23:00:00 GMT"),user)}</td>
            <td>{Restaurant(schedule[14],Date.parse("04/01/2022 23:00:00 GMT"),user)}</td>
          </tr>
          <tr>
            <th className="fixed">Lunch</th>
            <td>{Restaurant(schedule[5],Date.parse("03/28/2022 15:00:00 GMT"),user)}</td>
            <td>{Restaurant(schedule[6],Date.parse("03/29/2022 15:00:00 GMT"),user)}</td>
            <td>{Restaurant(schedule[7],Date.parse("03/30/2022 15:00:00 GMT"),user)}</td>
            <td>{Restaurant(schedule[8],Date.parse("03/31/2022 15:00:00 GMT"),user)}</td>
            <td>{Restaurant(schedule[9],Date.parse("04/01/2022 15:00:00 GMT"),user)}</td>
          </tr>
        </tbody>
      </Table>}
      </SecretArea>
    </Row>
    <Row>
      <Col>
      <span id="credits">&copy; <a href="https://cv.ebenstein.eu/">Michael Ebenstein</a></span>
      <br/>
      <br/>
      <h2 className="text-center">Other recommendations</h2>
      <br/>
      </Col>
    </Row>
    <Row>
      {data.map(x => 
        <Col key={x}>
          {Restaurant(x)}
        </Col>
      )}
    </Row>
  </Container>);
}

export default App;
