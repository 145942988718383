export const data = [
    {
 "name": "The English Restaurant", "url": "http://www.theenglishrestaurant.com/", "location": "Old Spitalfields Market", "type": "English", "dist": 13, "opening": "9am\u201311pm", "desc": "Snug, dark wood-pannelled in a 1600s townhouse serving classic British dishes all day." }, {
 "name": "The Ivy City Garden", "url": "https://theivycitygarden.com/?utm_source=YextGoogle&utm_medium=Organic", "location": "City Garden", "type": "British", "dist": 16, "opening": "8am\u201312am", "desc": "Contemporary brasserie setting with all-day menus, modern bar seating & ample natural light." }, {
 "name": "Zia Lucia - Aldgate East", "url": "https://zialucia.com/", "location": "East London", "type": "Pizza", "dist": 6, "opening": "11:30am\u201310:30pm", "desc": "Gourmet pizza with a variety of dough & toppings provided in a vibrant space with an open kitchen." }, {
 "name": "Monohon Ramen", "url": "http://www.monohonramen.com/", "location": "Old Street", "type": "Ramen", "dist": 33, "opening": "12\u20132:30pm, 6\u20139:30pm", "desc": "Classic ramen is given the full Japanese treatment at this relaxed, compact eatery." }, {
 "name": "Dom's Subs", "url": "https://www.impeccablesandwiches.co.uk/", "location": "Aldgate", "type": "Sandwich shop", "dist": 11, "opening": "11am\u20133pm", "desc": "Buzzing, unassuming operation offering super-sized sandwiches using housemade rolls to take away." }, {
 "name": "Flat Iron", "url": "http://www.flatironsteak.co.uk/", "location": "Old Spitalfields Market", "type": "Steak house", "dist": 14, "opening": "12\u201310pm", "desc": "Affordable steak place" }, {
 "name": "SMOKESTAK", "url": "https://smokestak.co.uk/", "location": "Spitalfields", "type": "Barbecue", "dist": 21, "opening": "12\u20133pm, 5\u201310pm", "desc": "A smoker and grill take centre stage at this stylishly rustic barbecue joint with a bar and terrace." }, {
 "name": "Burger & Beyond Shoreditch", "url": "http://burgerandbeyond.co.uk/", "location": "Shoreditch", "type": "Hamburger", "dist": 24, "opening": "12\u201310pm", "desc": "Sleek, minimalist outpost featuring creative, modern burgers & sides, plus cocktails & draft brews." }, {
 "name": "Franco Manca", "url": "http://www.francomanca.co.uk/restaurants/broadgate-circle/", "location": "Liverpool Street", "type": "Pizza", "dist": 19, "opening": "12\u201310:30pm", "desc": "Neapolitan-style sourdough pizzas with organic ingredients, plus real lemonade, in a tiled interior." }, {
 "name": "Beigel Bake Brick Lane Bakery", "url": "https://bricklanebeigel.co.uk/", "location": "Bricklane", "type": "Bagel shop", "dist": 22, "opening": "Open 24 hours", "desc": "24-hour bakery known for traditional Jewish-style filled bagels such as salt beef and smoked salmon." }, {
 "name": "Andina Spitalfields", "url": "https://andinaceviche.com/andina-picanteria-pisco-bar", "location": "Old Spitalfields Market", "type": "Peruvian", "dist": 11, "opening": "12\u20133pm, 5\u201310:30pm", "desc": "Peruvian ceviche, street food, smoothies and cocktails in a trendy, South American-inspired setting." }, {
 "name": "Flour & Grape", "url": "http://www.flourandgrape.com/", "location": "White Cube", "type": "Italian", "dist": 25, "opening": "12\u20133pm, 5:30\u201310pm", "desc": "Homemade pastas, gelato and interesting wines make for a simple formula at this easy-going Italian." }, {
 "name": "Honest Burgers St Katharine Docks", "url": "http://honestburgers.co.uk/locations/st-katharine-docks", "location": "St. Cathrines Docks", "type": "Hamburger", "dist": 8, "opening": "11:30am\u201310pm", "desc": " Experience homemade fresh crafted pasta in St Katharine Docks and Aldgate." }, {
 "name": "SUSHISAMBA London", "url": "https://www.sushisamba.com/", "location": "Bishopsgate", "type": "Japanese", "dist": 14, "opening": "12pm\u20131:30am", "desc": "Japanese-Brazilian-Peruvian sushi in cool design setting with 38th-floor Heron Tower views." }, {
    "name": "Butlers Wharf Chop House", "url": "http://www.chophouserestaurants.co.uk/", "location": "Tower Bridge", "type": "Chophouse", "dist": 17, "opening": "12\u201310:30pm", "desc": "A menu of chophouse classics, steaks & nostalgic British recipes, plus Tower Bridge views." },
    
    {"name": "Penny Squares",
     "url": "https://www.hilton.com/en/hotels/lonpypy-canopy-london-city/dining/", 
     "location": "Aldgate", 
     "type": "Middle eastern", 
     "dist": 5, 
     "opening": "6\u201310:00pm", 
     "desc": "Middle eastern restaurant located inside the Hilton Canopy Hotel"
     },


    {"name": "Smoking Goat",
    "url": "https://www.smokinggoatbar.com/", 
    "location": "Shoreditch", 
    "type": "Thai", 
    "dist": 24, 
    "opening": "12\u201311:00pm", 
    "desc": "Cool, laid-back restaurant serving quirky dishes & drinks inspired by Bangkok’s late-night canteens."
    }
    
    ]